import Container from '@/components/Container';
import Link from '@/components/Link';

export default function NotFound() {
  return (
    <div className="relative overflow-hidden bg-gradient-to-b from-gray-100 to-white py-10 lg:py-20">
      <Container>
        <div className="space-y-4 lg:flex lg:flex-col lg:items-center lg:py-20 lg:text-center">
          <h1 className="text-2xl font-bold tracking-tight lg:text-5xl">
            ¡Página no encontrada!
          </h1>
          <h2 className="text-lg lg:text-2xl">
            Lo sentimos, pero no se pudo encontrar la página que buscaba.
          </h2>
          <p className="max-w-screen-sm text-lg text-gray-500 lg:text-xl">
            Puede volver a nuestra&nbsp;
            <Link className="font-semibold text-green-600" href="/">
              página principal
            </Link>
            &nbsp;o&nbsp;
            <a
              className="font-semibold text-green-600"
              href="mailto:info@aibes.org"
            >
              enviarnos un mensaje
            </a>
            &nbsp;si no puede encontrar lo que está buscando.
          </p>
        </div>
      </Container>
    </div>
  );
}
